<style lang='less'>
</style>
<template>
  <div class="search-list-vue frame-page h-panel">
    <div class="h-panel-bar"><span class="h-panel-title">修仙记录</span></div>
    <div class="h-panel-bar">
	  <div style="padding: 10px 0 20px;"><Search :width="300" v-model="cx.text" @search="get()" showSearchButton></Search></div>
    </div>

	<div class="h-panel-body">
	  <Table :loading="loading" :datas="row.data">
	    <TableItem :width="50" title="序号">
	      <template v-slot="{ index }">{{ index + 1 }} </template>
	    </TableItem>
	    <TableItem title="操作详情" :width="800">
			  <template v-slot="{ index }"><Tag color="primary">{{ row.data[index].type }} </Tag> {{ row.data[index].text }} </template>
		</TableItem>
	  </Table>
	  <Space :height="10" />
	  <Pagination v-if="row.total > 0" align="right" v-model="fengye" @change="changePage" />
	</div>
  </div>
</template>
<script>
import utils from '@common/utils';
import Request from '@common/request';

export default {
  data() {
    return {
	  row:{
		data:[],
	  },
      loading: true,
	  cx:{
		page:1,
		pagesize: 10,
		total: 0,
		type:'',
		text:'',
	  },
	  fengye:{
		  page:1,
		  pagesize: 10,
		  total: 0,
	  },
	  type: null,
    };
  },
  mounted() {
    this.get();
  },
  watch: {
    params() {
	   this.cx.type=this.params.type
	   this.get();
    }
  },
  methods: {
    changePage(pageinfo) {
	   console.log(pageinfo)
	   this.cx.page=pageinfo.page
	   this.cx.pagesize=pageinfo.size
	   this.get();
    },
    get: async function () {
      let res = await Request.Log.index({cx:this.cx});
      if(res.code==1){
		  this.row=res;
		  this.cx.total=res.total
		  this.fengye.total=res.total
		  console.log(this.row);
		  this.loading = false; 
	  }
    },
  },
  computed: {

  }
};
</script>
